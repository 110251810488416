<template>
  <div class="parent">
    <div class="swiper-container swiper1">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="item in bannerList"
          :key="item.id"
          :data-item="item"
        >
          <div
            v-if="item.source_url"
            :style="`background-image: url('${item.source_url}')`"
            class="slide-img"
          ></div>
          <!-- <img :src="item.source_url" alt="" /> -->
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <!-- <div class="swiper-pagination"></div> -->
      <div class="swiper-button-prev-white"></div>
      <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
      <div class="swiper-button-next-white"></div>
      <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
    </div>
    <!-- <div class="titles share-color">
      <div class="banner-tit">WELCOME TO</div>
      <div class="banner-tit">BITASX</div>
      <div class="banner-desc">全球首选的数字资产交易服务商</div>
      <div class="banner-items flex js-sp align-center">
        <div class="flex align-center mr-20">
          <img src="@/assets/images/vector1.png" alt class="vector" />
          <div>买币便捷</div>
        </div>
        <div class="flex align-center mr-20">
          <img src="@/assets/images/fast.png" alt class="vector" />
          <div>交易快速</div>
        </div>
        <div class="flex align-center">
          <img src="@/assets/images/protect.png" alt class="vector" />
          <div>资产安全</div>
        </div>
      </div>
    </div> -->
    <Messagebox
      :text="$t('formTip.cashTip')"
      :dialog="state.dialogSafetyTip"
      :type="state.safetyType"
      @onconfirm="confirm"
      @viewSafeStatus="viewSafeStatus"
      @oncancer="onCancel"
    />
    <VerifiedBox
      :text="$t('formTip.certificationTip')"
      :type="state.type"
      :dialog="state.dialogVerfiedTip"
      @onconfirm="onVerfiedConfirm"
      @viewSafeStatus="onVerfiedConfirm"
      @oncancer="onVerfiedCancel"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, reactive } from "vue";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Messagebox from "@/components/messageBox";
import VerifiedBox from "@/components/verifiedBox";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
// swiper-bundle.min.css 决定了小圆点和左右翻页，如果不需要可以不引用
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";
export default defineComponent({
  name: "ScrollBanner",
  components: { Messagebox, VerifiedBox },
  props: {
    bannerList: Array,
  },
  setup(props) {
    const $router = useRouter();
    const $store = useStore();
    const state = reactive({
      safetyType: 0,
      defi_ore_status: true,
      wealth_management_status: true,
      fiat_currency_transaction_status: true,
      currency_transaction_status: true,
      lever_status: true,
      second_lever_status: true,
      meta_switch: false,
      dialogSafetyTip: false,
      dialogVerfiedTip: false,
      type: 0,
    });
    onMounted(() => {
      getStatus();
      initSwiper();
      // getStatus();
    });
    const initSwiper = () => {
      new Swiper(".swiper1", {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next-white",
          prevEl: ".swiper-button-prev-white",
          disabledClass: "my-button-disabled",
          hideOnClick: true,
        },
        watchOverflow: true, //因为仅有1个slide，swiper无效
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: props.bannerList.length > 1 ? true : false,
        on: {
          click: function(e) {
            if (e.clickedIndex != undefined) {
              const curIndex = this.realIndex;
              const item = props.bannerList[curIndex];
              toPath(item);
            }
          },
        },
      });
    };
    const onVerfiedConfirm = () => {
      state.dialogVerfiedTip = false;
      $router.push("/person/Authentication");
    };
    const onVerfiedCancel = () => {
      state.dialogVerfiedTip = false;
    };
    const confirm = () => {
      state.dialogSafetyTip = false;
      $router.push("/person/Advanced");
    };
    const viewSafeStatus = () => {
      state.dialogSafetyTip = false;
      $router.push("/person/Advanced");
    };
    const onCancel = () => {
      state.dialogSafetyTip = false;
    };
    //是否需要实名
    const isVerified = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "verify_switch") {
          if (
            ele.value[5] &&
            $store.state.user.securityInfo &&
            $store.state.user.securityInfo.user_auth_level !== 2
          ) {
            if ($store.state.user.securityInfo.user_auth_level !== 4) {
              state.type = 1;
            }
            flag = true;
          }
        }
      });
      return flag;
    });
    const systemInfo = computed(() => {
      return $store.state.user.systemInfo;
    });
    const securityInfo = computed(() => {
      return $store.state.user.securityInfo;
    });
    const getStatus = () => {
      if (systemInfo.value) {
        systemInfo.value.forEach((ele) => {
          if (ele.key === "defi_ore_status") {
            state.defi_ore_status = ele.value;
          }
          if (ele.key === "wealth_management_status") {
            state.wealth_management_status = ele.value;
          }
          if (ele.key === "fiat_currency_transaction_status") {
            state.fiat_currency_transaction_status = ele.value;
          }
          if (ele.key === "currency_transaction_status") {
            state.currency_transaction_status = ele.value;
          }
          if (ele.key === "lever_status") {
            state.lever_status = ele.value;
          }
          if (ele.key === "second_lever_status") {
            state.second_lever_status = ele.value;
          }
          if (ele.key === "meta_switch") {
            state.meta_switch = ele.value;
          }
        });
      }
    };
    const toPath = (item) => {
      if (item.banner_type === 1) {
        if (item.banner_url) {
          window.open(item.banner_url);
        }
      } else {
        if (item.banner_status) {
          if (
            item.banner_url === "CURRENCY_TRANSACTION" &&
            state.currency_transaction_status
          ) {
            $router.push("/deal");
          }
          if (item.banner_url === "LEVER_TRANSACTION" && state.lever_status) {
            $store.commit("user/SET_LEVER_PAGE", "lever");
            $router.push("/lever");
          }
          if (
            item.banner_url === "SECOND_LEVER_TRANSACTION" &&
            state.second_lever_status
          ) {
            $store.commit("user/SET_LEVER_PAGE", "second");
            $router.push("/secondlever");
          }
          if (
            item.banner_url === "LEGAL_TRANSACTION" &&
            state.fiat_currency_transaction_status
          ) {
            $router.push("/fiat");
          }
          //判断后台是否有开启
          if (
            item.banner_url === "WEALTH_MANAGEMENT" &&
            state.wealth_management_status
          ) {
            $router.push("/wealthFinancial");
          }
          if (item.banner_url === "MINING" && state.defi_ore_status) {
            $router.push("/defiFinancial");
          }
          //判断是否登录
          if (item.banner_url === "RECHARGE") {
            $router.push("/person/recharge");
          }
          if (item.banner_url === "WITHDRAW") {
            if (isVerified.value) {
              state.dialogVerfiedTip = true;
              return false;
            }
            if (securityInfo.value) {
              if (
                securityInfo.value.user_safety_certificate_status &&
                securityInfo.value.user_safety_certificate_type !== 2
              ) {
                if (securityInfo.value.user_safety_certificate_type !== 4) {
                  state.safetyType = 1;
                }
                state.dialogSafetyTip = true;
                return;
              }
            }
            $router.push("/person/cash");
          }
          if (item.banner_url === "NOTICE") {
            $router.push("/detail?id=" + item.banner_detail_id + "&type=1");
          }
          if (item.banner_url === "MESSAGE") {
            $router.push("/detail?id=" + item.banner_detail_id + "&type=3");
          }
          if (item.banner_url === "HELP") {
            $router.push("/detail?id=" + item.banner_detail_id + "&type=2");
          }
          if (item.banner_url === "META_INFORMATION" && state.meta_switch) {
            $store.commit("user/SET_NFT_PAGE", "universe");
            $router.push(
              "/universeDetail?id=" + item.banner_detail_id + "&type=meta"
            );
          }
          if (item.banner_url === "META_REPORT" && state.meta_switch) {
            $store.commit("user/SET_NFT_PAGE", "universe");
            $router.push(
              "/universeDetail?id=" + item.banner_detail_id + "&type=report"
            );
          }
        }
      }
    };
    return {
      toPath,
      systemInfo,
      state,
      getStatus,
      confirm,
      onCancel,
      onVerfiedConfirm,
      onVerfiedCancel,
      isVerified,
      securityInfo,
      initSwiper,
      viewSafeStatus,
    };
  },
});
</script>

<style lang="less" scoped>
.swiper-slide {
  height: 820px;
  .slide-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.swiper-button-prev-white,
.swiper-button-next-white {
  position: absolute;
  top: 50%;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 10px;
  right: auto;
  isolation: isolate;
}
.swiper-button-next-white {
  right: 10px;
  left: auto;
}
.swiper-button-next-white {
  background-image: url("../../assets/images/next.png");
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
}
.swiper-button-prev-white {
  background-image: url("../../assets/images/prev.png");
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
}
.my-button-disabled {
  display: none;
}
.titles {
  position: absolute;
  top: 253px;
  left: 160px;
  z-index: 2;
  text-align: start;
  .banner-tit {
    font-weight: 900;
    font-size: 48px;
    line-height: 62px;
  }
  .banner-desc {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    margin-top: 5px;
    margin-bottom: 37px;
  }
  .banner-items {
    font-weight: normal;
    font-size: 19.8333px;
    line-height: 28px;
    background: #3d67fa;
    border-radius: 6px;
    padding: 2px 14px;
  }
}
.vector {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin-inline-end: 7px;
}
.mr-20 {
  margin-inline-end: 20px;
}
</style>
